import React, { useEffect, useMemo, useState } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import { TreeView } from '@mui/x-tree-view/TreeView'
import { useSnackbar } from 'notistack'
import { NavLink, Redirect } from 'react-router-dom'

import { useAuth } from '../contexts/AuthContext'
import { useCluster } from '../contexts/ClusterContext'
import { iconsObj } from '../icons/internalIcons'
import Logo from '../icons/Logo'
import PangoToTessyLogo, { PangolinIcon } from '../icons/PangoToTessyLogo'
import { internalTypesOptionsNoBase } from './Constants'

import { MicroreactProject } from '../response_types/backend/MicroreactProjects'

const hoverColor = '#E8E8E8'

const StyledButton = styled(Button)(() => ({
  ':hover': { color: hoverColor },
}))

type MenuItemMetadata = {
  href: string
  key: string
  icon?: React.ReactNode
  title: string | React.ReactElement
}

const NavLinkButton: React.FC<MenuItemMetadata> = ({ href, icon = undefined, title }) => {
  return (
    <NavLink to={href} style={{ textDecoration: 'none', color: 'inherit' }}>
      <StyledButton color='inherit' startIcon={icon} sx={{ textTransform: 'none' }}>
        {title}
      </StyledButton>
    </NavLink>
  )
}

const SummariesTreeRoot: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <MenuItem>
      <Box sx={{ flexGrow: 1, maxWidth: 500 }}>
        <TreeView
          defaultCollapseIcon={iconsObj.EXPAND_MORE}
          defaultExpandIcon={iconsObj.NEXT}
          sx={{
            '& .MuiTreeItem-content.Mui-selected, & .MuiTreeItem-content.Mui-selected:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          {props.children}
        </TreeView>
      </Box>
    </MenuItem>
  )
}

type SummariesTreeNavProps = {
  anchorElement: HTMLElement | null
  handleClose: () => void
}

const SummariesTreeNav: React.FC<SummariesTreeNavProps> = ({ anchorElement, handleClose }) => {
  const { backendRequestJson } = useAuth()

  const [microreactProjects, setMicroreactProjects] = useState<MicroreactProject[]>([])

  const fetchData = async () => {
    const endpoint = '/microreact_projects'
    const response = await backendRequestJson({ endpoint })
    if (response.status === 200) {
      return response.json as MicroreactProject[]
    }
    return undefined
  }

  useEffect(() => {
    fetchData().then((response) => {
      if (response) {
        setMicroreactProjects(response)
      }
    })
  }, [])

  return (
    <Menu
      id='summaries-menu'
      anchorEl={anchorElement}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      open={Boolean(anchorElement)}
      onClose={handleClose}
    >
      {internalTypesOptionsNoBase.map((m) => {
        if (m.id === 'antimicrobial_resistance') {
          return (
            <SummariesTreeRoot key={`summaries-tree-root-${m.id}`}>
              <TreeItem nodeId={m.id} label={m.label}>
                <TreeItem
                  nodeId={`${m.id}-aggregate`}
                  label={
                    <NavLink to='/evaluation-antimicrobial-resistance-aggregate' style={{ textDecoration: 'none', color: 'inherit' }}>
                      Patient and genomic surveillance table
                    </NavLink>
                  }
                  onClick={handleClose}
                ></TreeItem>
                <TreeItem
                  nodeId={`${m.id}-spread-dashboard`}
                  label={
                    <NavLink to='/spread' style={{ textDecoration: 'none', color: 'inherit' }}>
                      Core genome MLST minimum spanning tree
                    </NavLink>
                  }
                  onClick={handleClose}
                ></TreeItem>
                <TreeItem nodeId={`${m.id}-microreact`} label='Regional and temporal spread visualisation'>
                  {microreactProjects.map((project) => {
                    return (
                      <TreeItem
                        key={`microreact-list-item-${project.id}`}
                        nodeId={`${m.id}-${project.id}`}
                        label={project.name}
                        onClick={() => {
                          window.open(project.url)
                        }}
                      ></TreeItem>
                    )
                  })}
                </TreeItem>
              </TreeItem>
            </SummariesTreeRoot>
          )
        } else {
          return (
            <SummariesTreeRoot key={`summaries-tree-root-${m.id}`}>
              <TreeItem nodeId={m.id} label={m.label}></TreeItem>
            </SummariesTreeRoot>
          )
        }
      })}
    </Menu>
  )
}

const stringToColor = (string: string) => {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

const stringAvatar = (name: string) => {
  return {
    sx: { bgcolor: stringToColor(name) },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  }
}

const NavBar: React.FC = () => {
  // hooks
  const { logout, currentUser, token } = useAuth()
  const { clusterStyle } = useCluster()

  // snackbar
  const { enqueueSnackbar } = useSnackbar()

  // handle showing menus
  const [anchorElUser, setAnchorElUser] = useState<HTMLElement | null>(null)
  const [anchorElNav, setAnchorElNav] = useState<HTMLElement | null>(null)
  const [anchorSummariesTree, setAnchorSummariesTree] = useState<HTMLElement | null>(null)

  const handleOpenSummariesTree = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorSummariesTree(event.currentTarget)
  }

  const handleCloseSummariesTree = () => {
    setAnchorSummariesTree(null)
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleLogout = () => {
    handleCloseUserMenu()

    try {
      logout()
      return <Redirect to='/login' />
    } catch {
      enqueueSnackbar('Failed to logout', { variant: 'error' })
    }
  }

  const menuItems = useMemo((): MenuItemMetadata[] => {
    const items = [
      { href: '/plates', key: 'plates', icon: iconsObj.PLATES, title: 'Plates' },
      { href: '/runs', key: 'runs', icon: iconsObj.RUNS, title: 'Runs' },
      { href: '/samples', key: 'samples', icon: iconsObj.SAMPLES, title: 'Samples' },
      { href: '/analyses-sets', key: 'analyses-sets', icon: iconsObj.ANALYSES_SETS, title: 'Analyses Sets' },
      { href: '/sample-batches', key: 'sample-batches', icon: iconsObj.SAMPLE_BATCHES, title: 'Sample Batches' },
    ]

    if (currentUser?.is_sysadmin) {
      items.push({ href: '/data/', key: 'data', icon: iconsObj.DATA, title: 'Data' })
    }
    return items
  }, [currentUser])

  return (
    <>
      {token && clusterStyle && (
        <AppBar position='sticky' sx={{ backgroundColor: clusterStyle.navbarColor }}>
          <Container maxWidth='xl'>
            <Toolbar disableGutters>
              {/* This is part that is rendered for mobile environments */}
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size='large'
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={handleOpenNavMenu}
                  color='inherit'
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id='menu-appbar'
                  anchorEl={anchorElNav}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{ display: { xs: 'block', md: 'none' } }}
                >
                  {menuItems.map((m) => {
                    return (
                      <MenuItem component={NavLink} to={m.href} key={m.key} onClick={handleCloseNavMenu}>
                        <ListItemIcon>{m.icon}</ListItemIcon>
                        <ListItemText>{m.title}</ListItemText>
                      </MenuItem>
                    )
                  })}
                  <MenuItem onClick={handleOpenSummariesTree}>
                    <ListItemIcon>{iconsObj.SUMMARIZE}</ListItemIcon>
                    <ListItemText>Summaries</ListItemText>
                  </MenuItem>
                  <SummariesTreeNav anchorElement={anchorSummariesTree} handleClose={handleCloseSummariesTree} />
                </Menu>
              </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1 }}>
                <NavLinkButton href='/' key='navbar_home_link2' title={<Logo />} />
              </Box>

              {/* This is part that is rendered for desktop environments */}
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <NavLinkButton href='/' key='navbar_home_link2' title={<Logo />} />
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {menuItems.map((m) => {
                  return <NavLinkButton href={m.href} key={m.key} icon={m.icon} title={m.title} />
                })}
                <StyledButton color='inherit' sx={{ textTransform: 'none' }} startIcon={iconsObj.SUMMARIZE} onClick={handleOpenSummariesTree}>
                  Summaries
                </StyledButton>
              </Box>

              {/* This is part that is rendered for all environments */}
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title='Open settings'>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar {...stringAvatar(`${currentUser?.name} ${currentUser?.surname}`)} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id='menu-appbar'
                  anchorEl={anchorElUser}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem component={NavLink} to='/change-password' key='change_password' id='change_password_btn' onClick={handleCloseUserMenu}>
                    <ListItemIcon>{iconsObj.PASSWORD}</ListItemIcon>
                    <ListItemText>Change password</ListItemText>
                  </MenuItem>
                  {currentUser?.is_superuser && (
                    <MenuItem component={NavLink} to='/signup' key='signup' id='register_user_btn' onClick={handleCloseUserMenu}>
                      <ListItemIcon>{iconsObj.SIGNUP}</ListItemIcon>
                      <ListItemText>Register User</ListItemText>
                    </MenuItem>
                  )}
                  {currentUser?.is_superuser && (
                    <MenuItem component={NavLink} to='/users' key='users' id='user_managment_btn' onClick={handleCloseUserMenu}>
                      <ListItemIcon>{iconsObj.USER_MANAGEMENT}</ListItemIcon>
                      <ListItemText>User Management</ListItemText>
                    </MenuItem>
                  )}
                  {currentUser?.is_superuser && (
                    <MenuItem
                      component={NavLink}
                      to='/end-user-update-messages'
                      key='end-user-update-messages'
                      id='end_user_update_messages_btn'
                      onClick={handleCloseUserMenu}
                    >
                      <ListItemIcon>{iconsObj.QUESTIONABLE}</ListItemIcon>
                      <ListItemText>End User Update Msgs</ListItemText>
                    </MenuItem>
                  )}
                  {currentUser?.is_superuser && (
                    <MenuItem component={NavLink} to='/admin' key='admin' id='admin' onClick={handleCloseUserMenu}>
                      <ListItemIcon>{iconsObj.ADMIN}</ListItemIcon>
                      <ListItemText>Admin</ListItemText>
                    </MenuItem>
                  )}
                  {currentUser?.is_sysadmin && <Divider light color='black' variant='middle' />}
                  {currentUser?.is_sysadmin && (
                    <MenuItem component={NavLink} to='/tessy' key='tessy' id='pango_to_tessy_btn' onClick={handleCloseUserMenu}>
                      <ListItemIcon>
                        <PangolinIcon />
                      </ListItemIcon>
                      <PangoToTessyLogo />
                    </MenuItem>
                  )}
                  {currentUser?.is_sysadmin && (
                    <MenuItem component={NavLink} to='/pipelines' key='pipelines' id='pipelines_btn' onClick={handleCloseUserMenu}>
                      <ListItemIcon>{iconsObj.PIPELINES}</ListItemIcon>
                      <ListItemText>Pipelines</ListItemText>
                    </MenuItem>
                  )}
                  <Divider light color='black' variant='middle' />
                  <MenuItem component={NavLink} to='/contact-us' key='contact-us' id='contact_us_btn' onClick={handleCloseUserMenu}>
                    <ListItemIcon>{iconsObj.CONTACT_US}</ListItemIcon>
                    <ListItemText>Contact us</ListItemText>
                  </MenuItem>
                  <MenuItem component='a' href='#logout' key='logout' id='logout_btn' onClick={handleLogout}>
                    <ListItemIcon>{iconsObj.LOGOUT}</ListItemIcon>
                    <ListItemText>Log Out</ListItemText>
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      )}
    </>
  )
}

export default NavBar
